import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Div,
  Row,
  SideMenu,
  Button,
  Paper,
  Col,
  H1,
} from "qdm-component-library";
import {
  TopHeader,
  PatientCard,
  PatientDashboardNavBar,
  Addmemberotp,
  CardList,
  EmptyCard,
  ViewCard,
  ModelComponent,
  Addmember,
  Addmemberform,
  PatientCareTypeCard,
  LoadingComp,
} from "../../components";
import { TableList, BillNav, RefferalConfirm } from "./";
// import ConfirmationPage from "../confirmBooking";
import CancelBookingPage from "../cancelBooking";
import { Routes } from "../../router/routes";
import {
  AlertProps,
  checkError,
  getUserInfo,
  makeName,
  _calculateAge,
} from "../../utils";
import AppointmentAddMemberSuccessPage from "../../components/appointment_booked/index";
import { ConfirmationPage } from "../../screens";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { checkWithCasbin } from "../../utils/permissonHandling";
import { AlertContext } from "../../contexts";
import { withAllContexts } from "../../HOCs";
const casbinjs = require("casbin.js");

const tableHeader = [
  "S.No",
  "Bill.No",
  "Date",
  "Gross Amt",
  "Discount",
  "Net Payable",
  "Paid",
  "Outstanding",
  "Settle Now",
];

class PatientDashBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      cancelbooking: false,
      cancelbookingreason: {},
      cancelbookings: 1,
      activeId: 1,
      addmmber: false,
      sidebar: this.props.location?.state?.addMember,
      memberForm: this.props.location?.state?.addMember,
      searchMenber: this.props.location?.state?.addMember,
      memberSuccessPage: this.props.location?.state?.addMember,
      appointmentId: 0,
      choosemember: [],
      memberIds: [],
      chooseSearch: null,
      searchmmrdata: null,
      loggedInState: {},
      patientDetails: {},
      isBookAppoinment: false,
      fin_data: [],
      selectOption: {},
      editOpen: false,
      personDetails: {},
      callcen: false,
      permission: {},
      permissionData: [],
      loading: true,
    };
    this.btnClick = this.btnClick.bind(this);
    this.openView = this.openView.bind(this);
    this.setActiveId = this.setActiveId.bind(this);
    this.toggleSideBar = this.toggleSideBar.bind(this);
    this.changeSideBar = this.changeSideBar.bind(this);
  }

  handleEdit = (isEdit) => {
    this.setState({ ...this.state, editOpen: isEdit });
    // console.log("patient",this.state?.patientDetails);
    // return
    this.props.history.push({
      pathname: Routes.assemblePatioentRegister,
      state: {
        patientData: this.state?.patientDetails?.username,
        isModify: true,
        key:
          this.props.location?.state?.key ||
          this.state.patientDetails?.pid?.split("/")?.[1],
        patientId: this.props.location?.state?.patientId,
        // this.state.patientDetails.patientId,
        ids: this.state.patientDetails.id,
        personId: this.state.patientDetails?.id,
        Ispatient: false,
        idedit: true,
      },
    });
  };

  handelChangeSelect = (v) => {
    this.setState({
      chooseSearch: v,
    });
  };

  changeSideBar() {
    this.setState(
      {
        ...this.state,
        sidebar: false,
        memberForm: false,
        searchMenber: false,
        memberSuccessPage: false,
      },
      () => {
        window.location.reload();
      }
    );
  }

  async fetchInfo() {
    if (!this.props.location?.state?.mobileNumber) {
      window.location.href = "/";
      return;
    }
    const info = getUserInfo();
    let mobileNo = this.props.location?.state?.mobileNumber;
    const persondata = await this.props.READ_PERSON({
      phone: mobileNo,
    });
    const personDetails = await this.props.PATIENT_DETAILS_READ({
      key:
        this.props.location?.state?.key ||
        persondata?.payload?.data?.pid?.split("/")?.[1],
    });
    const val = await this.props.GET__FOLLOW_UP_APPOINTMENT({
      patientId:
        // 'Patient/10238'
        personDetails.payload?.data?.[0]?._id,
    });
    console.log(val?.payload?.data);
    this.setState({
      followUpAppointmentData: val?.payload?.data,
    });
    if (!this.state.callcen && personDetails.payload.data.length > 0) {
      if (
        personDetails.payload?.data?.[0]?.isdeceased?.display === "Deceased" ||
        !personDetails.payload?.data?.[0]?.active
      ) {
        await this.props.UPSERT_CANCEL_APP_PATIENT({
          personId: this.props.location?.state?.userId,
        });
        if (this.props?.appointments?.data?.currentVisist?.length > 0) {
          // const dlt = this.props?.appointments?.data?.currentVisist?.map(async (v) => (
          //     await this.props.ORGANIZATION_VISIT_REGISTRATION({
          //         payloaddata: {
          // Json['cancel_date'] = moment().unix();
          // Json['revision_date'] = 0;
          // },
          //         enId: this.state.encount._id
          //     })
          // ))
        }
      }
    }
    this.setState({
      loggedInState: { ...info },
      patientDetails: persondata?.payload?.data,
      personDetails: personDetails?.payload?.data?.[0],
      primaryMember: persondata?.payload?.data ?? {},
    });
    const userId = persondata?.payload?.data?.id;
    const data = await this.props.GET_RELATED_PERSON({ userid: userId });
    const choosemember = [];
    const memberIds = [userId];
    if (data?.payload?.data && Array.isArray(data?.payload?.data)) {
      data.payload.data.forEach((val) => {
        choosemember.push({
          src: "",
          label: val?.name?.[0]?.text || val?.name?.[0]?.given || "",
          id: val?.PersonID,
        });
        memberIds.push(val?.PersonID);
      });
    }

    this.setState({
      choosemember,
      memberIds,
    });
    await this.props.ALL_APPOINTMENTS({
      userId,
      assemble: true,
    });
  }

  async componentDidMount() {
    let permission_ = await checkWithCasbin(["patient_dashboard"]);
    this.setState({ ...this.state, permissionData: permission_ });
    let d =
      this.state?.permissionData?.write?.indexOf("patientBannerStatus") > -1;

    const Arr = localStorage.getItem("role_data");
    const arr1 = JSON.parse(atob(Arr))[localStorage.getItem("role_name")]
      .access;
    const permission = { callcenter: arr1 };
    const authorizer = new casbinjs.Authorizer("manual", permission);
    authorizer.setPermission(permission);
    const result = await authorizer.can("callcenter", "4");
    let dataList = await checkWithCasbin(["patient_dashboard"]);
    this.setState({ callcen: result, permission: dataList });

    await this.fetchInfo();
    const patientID = this.props?.location?.state?.patientId || 0;
    if (this.props.GET_FINANCIAL) {
      const fin_data = await this.props.GET_FINANCIAL({ patientId: patientID });
      if (fin_data?.payload?.data) {
        const fin_DATA = fin_data?.payload?.data?.map((v) => {
          const setdata = v?.payersprioritycoverage?.[0];
          return {
            pic: "images/Group 90507.svg",
            name: null,
            care_type: setdata?.guarantorid,
            title_name: setdata?.receivertype + ", " + setdata?.policyid,
          };
        });
        this.setState({
          selectOption: fin_DATA[0],
          fin_data: fin_DATA,
          loading: false,
        });
      }
    }
  }

  btnClick() {
    if (
      this.state?.permissionData?.write?.indexOf("patientBannerEditIcon") > -1
    ) {
      this.setState({ isBookAppoinment: true });
      // this.props.history.push(Routes.doctorConsultation);
    } else {
      let userId = this.props.location?.state?.userId;
      this.props.history.push({
        pathname: Routes.doctorConsultation,
        state: {
          userId: userId,
          mobileNo: this.props.location?.state?.mobileNumber,
          appointmentId: this.props.location?.state?.appointmentId,
          patientId: this.props.location?.state?.patientId,
          walkin: this.props.location?.state?.guest,
        },
      });
    }
  }

  setValue(name, value) {
    this.setState({
      [name]: value,
    });
  }

  selectPayBills() {
    this.props.history.push({
      pathname: Routes.collection,
      state: { nextRoute: Routes.PatientDashBoard },
    });
  }

  openView = (appointmentId) => {
    this.setState({
      open: !this.state.open,
      appointmentId,
    });
  };

  handleVisit = async (data, role) => {
    const appointmentData_encounter =
      await this.props.APPOINTMENT_ID_GET_ENCOUNTE_DETAILS({
        appointmentId: data,
      });

    if (role === "view") {
      this.props.history.push({
        pathname: Routes.patientVisitModify,
        state: {
          role: role,
          appointmentId: data.id || data,
          walkin: true,
          appointment_Id: data._id || data,
        },
      });
    } else if (role === "modify") {
      if (
        appointmentData_encounter?.payload?.data?.[0]?.statusexpand?.display.toLowerCase() ===
          "checked-in" &&
        appointmentData_encounter?.payload?.data?.[0]?.activestatus
      ) {
        this.props.history.push({
          pathname: Routes.patientVisitModify,
          state: {
            role: role,
            appointmentId: data.id || data,
            walkin: true,
            appointment_Id: data._id || data,
          },
        });
      } else {
        this.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: appointmentData_encounter?.payload?.data?.[0]?.activestatus
            ? "Consult in-progress can't be Modified"
            : "Visit has been cancelled cannot modify",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      }
    } else if (role === "cancel") {
      if (
        appointmentData_encounter?.payload?.data?.[0]?.statusexpand?.display.toLowerCase() ===
          "checked-in" &&
        appointmentData_encounter?.payload?.data?.[0]?.activestatus
      ) {
        this.props.history.push({
          pathname: Routes.patientVisitCancel,
          state: {
            role: role,
            appointmentId: data.id || data,
            walkin: true,
            appointment_Id: data._id || data,
            // patientId: data?.Person?.[0]?.id,
          },
        });
      } else {
        this.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: appointmentData_encounter?.payload?.data?.[0]?.activestatus
            ? "Consult in-progress can't be cancel"
            : "Visit already cancelled",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      }
    }
  };

  handlesidebarCancelbooking = (id) => {
    if (id === "closing") {
      this.props.ALL_APPOINTMENTS({
        userId: this.state.patientDetails?.id || 0,
      });
    }
    this.setState({
      cancelbookings: 1,
      cancelbookingreason: {},
      cancelbooking: !this.state.cancelbooking,
      appointmentId: id || this.state.appointmentId,
    });
  };
  handleSelectCancelbooking = (v) => {
    this.setState({
      cancelbookingreason: v,
    });
  };
  handlecahngeCancelbooking = async (v, number) => {
    if (v === "back") {
      this.setState({
        cancelbookings: 1,
      });
      return;
    }
    if (!v) {
      this.handlesidebarCancelbooking();
      window.location.href = "/dashboard";
      return;
    }
    let mobile = number;
    if (!number) {
      const userInfo = getUserInfo();
      mobile = userInfo.mobile;
    }
    if (v === 1) {
      const data = await this.props.GENERATE_OTP({ mobileNo: mobile });
      const { isError, errMsg } = checkError(data?.payload);
      if (isError) {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: errMsg,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return;
      }
    } else if (v === 2) {
      this.fetchInfo();
    }
    this.setState({
      cancelbookings: v,
      otpnumber: number,
    });
  };
  setActiveId(Id) {
    this.setState({ activeId: Id });
  }
  toggleSideBar = (boolean) => {
    this.setState({
      ...this.state,
      sidebar: boolean,
      memberForm: false,
      searchMenber: true,
      addmmber: false,
      memberSuccessPage: false,
    });
  };
  nextSection = (member, signUpData, l) => {
    if (signUpData === "addMember") {
      this.setState({
        ...this.state,
        signUpData: l ? l : signUpData,
        searchMenber: false,
        memberForm: false,
        memberOtp: true,
        memberSuccessPage: false,
        addmmber: true,
      });
      return;
    } else if (member === "addmemberform") {
      this.setState({
        ...this.state,
        searchMenber: false,
        memberForm: true,
        memberOtp: false,
        addmmber: false,
        signUpData,
      });
    } else if (member === "addmemberotp") {
      if (signUpData) {
        this.setState({
          ...this.state,
          signUpData,
          searchMenber: false,
          memberForm: false,
          memberOtp: true,
          memberSuccessPage: false,
          addmmber: false,
        });
      } else {
        this.setState({
          ...this.state,
          searchMenber: false,
          memberForm: false,
          memberOtp: true,
          memberSuccessPage: false,
        });
      }
    } else if (member === "back") {
      this.setState({
        ...this.state,
        searchMenber: true,
        memberForm: false,
        memberOtp: false,
        memberSuccessPage: false,
      });
    } else if (member === "member_succss") {
      this.setState({
        ...this.state,
        searchMenber: false,
        memberForm: false,
        memberOtp: false,
        sidebar: true,
        memberSuccessPage: true,
        signUpData,
      });
    } else if (member === "movenextscreen") {
      this.setState({
        ...this.state,
        searchMenber: true,
        memberForm: false,
        memberOtp: false,
        sidebar: false,
        memberSuccessPage: false,
      });
    }
  };

  handelChangeSelect = (v) => {
    this.setState({
      chooseSearch: v,
    });
  };

  containedButton = {
    backgroundColor: "#0071F2",
    borderColor: "#0071F2",
    borderRadius: 8,
    padding: "8px 15px",
    minWidth: 100,
    fontWeight: "600",
    fontSize: 12,
  };

  pastVisitCradConstruction = () => {
    const { permission } = this.state;
    let constructCard = [];
    if (
      this.props?.appointments?.data?.pastAppointmentThisWeek?.length === 0 &&
      this.props?.appointments?.data?.pastAppointmentLastWeek?.length === 0 &&
      this.props?.appointments?.data?.pastAppointmentThisMonth?.length === 0 &&
      this.props?.appointments?.data?.pastAppointmentLastMonth?.length === 0
    ) {
      constructCard.push({
        id: 1,
        name: "Empty",
        component: <EmptyCard parent_id={"patient_dashboard"} />,
        // component: <p>Hi</p>
      });
    } else {
      // console.clear()
      console.log(this.props?.appointments?.data);
      if (this.props?.appointments?.data?.pastAppointmentThisWeek?.length > 0) {
        constructCard.push({
          id: 2,
          name: "This Week",
          component:
            this.props?.appointments?.data?.pastAppointmentThisWeek.map(
              (val, i) => (
                <>
                  {i === 0 && (
                    <H1
                      id={`${i}THIS_WEEK`}
                      key={"0"}
                      name="THIS WEEK"
                      className="pc_medium"
                      style={{
                        color: "#6F6F6F",
                        fontSize: "14px",
                        margin: 0,
                        textTransform: "uppercase",
                        letterSpacing: 0,
                        lineHeight: 1,
                        padding: "0px 17px",
                        marginBottom: 8,
                      }}
                    ></H1>
                  )}
                  <ViewCard
                    parent_id={"patient_dashboard" + i}
                    patientDetails={this.state.patientDetails}
                    openView={this.openView}
                    val={val}
                    key={i}
                    isShowLabel
                    noBookNow={
                      !(permission?.write?.indexOf("bookAppoinment") > -1)
                    }
                    nomodify={
                      !(permission?.write?.indexOf("modifyAppoinment") > -1)
                    }
                    nocancel={
                      !(permission?.write?.indexOf("cancleAppoinment") > -1)
                    }
                    showBtn
                    past
                    isDiabled={
                      this.state.personDetails?.isdeceased?.display ===
                        "Deceased" || !this.state?.personDetails?.active
                    }
                    handlesidebarCancelbooking={this.handlesidebarCancelbooking}
                  />
                </>
              )
            ),
        });
      }
      if (this.props?.appointments?.data?.pastAppointmentLastWeek?.length > 0) {
        constructCard.push({
          id: 3,
          name: "Last Week",
          component:
            this.props?.appointments?.data?.pastAppointmentLastWeek.map(
              (val, i) => (
                <>
                  {i === 0 && (
                    <H1
                      id={`${i}_LAST_WEEK`}
                      key={"0"}
                      name="LAST WEEK"
                      className="pc_medium"
                      style={{
                        color: "#6F6F6F",
                        fontSize: "14px",
                        margin: 0,
                        textTransform: "uppercase",
                        letterSpacing: 0,
                        lineHeight: 1,
                        padding: "0px 17px",
                        marginBottom: 8,
                      }}
                    ></H1>
                  )}
                  <ViewCard
                    parent_id={"patient_dashboard" + i}
                    patientDetails={this.state.patientDetails}
                    openView={this.openView}
                    val={val}
                    key={i}
                    isShowLabel
                    noBookNow={
                      !(permission?.write?.indexOf("bookAppoinment") > -1)
                    }
                    nomodify={
                      !(permission?.write?.indexOf("modifyAppoinment") > -1)
                    }
                    nocancel={
                      !(permission?.write?.indexOf("cancleAppoinment") > -1)
                    }
                    showBtn
                    past
                    isDiabled={
                      this.state.personDetails?.isdeceased?.display ===
                        "Deceased" || !this.state?.personDetails?.active
                    }
                    handlesidebarCancelbooking={this.handlesidebarCancelbooking}
                  />
                </>
              )
            ),
        });
      }
      if (
        this.props?.appointments?.data?.pastAppointmentThisMonth?.length > 0
      ) {
        constructCard.push({
          id: 4,
          name: "This Month",
          component:
            this.props?.appointments?.data?.pastAppointmentThisMonth.map(
              (val, i) => (
                <>
                  {i === 0 && (
                    <H1
                      id={`${i}THIS_MONTH`}
                      key={"0"}
                      name="THIS MONTH"
                      className="pc_medium"
                      style={{
                        color: "#6F6F6F",
                        fontSize: "14px",
                        margin: 0,
                        textTransform: "uppercase",
                        letterSpacing: 0,
                        lineHeight: 1,
                        padding: "0px 17px",
                        marginBottom: 8,
                      }}
                    ></H1>
                  )}
                  <ViewCard
                    parent_id={"patient_dashboard" + i}
                    patientDetails={this.state.patientDetails}
                    openView={this.openView}
                    val={val}
                    key={i}
                    isShowLabel
                    noBookNow={
                      !(permission?.write?.indexOf("bookAppoinment") > -1)
                    }
                    nomodify={
                      !(permission?.write?.indexOf("modifyAppoinment") > -1)
                    }
                    nocancel={
                      !(permission?.write?.indexOf("cancleAppoinment") > -1)
                    }
                    showBtn
                    past
                    isDiabled={
                      this.state.personDetails?.isdeceased?.display ===
                        "Deceased" || !this.state?.personDetails?.active
                    }
                    handlesidebarCancelbooking={this.handlesidebarCancelbooking}
                  />
                </>
              )
            ),
        });
      }
      if (
        this.props?.appointments?.data?.pastAppointmentLastMonth?.length > 0
      ) {
        constructCard.push({
          id: 5,
          name: "Last Month",
          component:
            this.props?.appointments?.data?.pastAppointmentLastMonth.map(
              (val, i) => (
                <>
                  {i === 0 && (
                    <H1
                      id={`${i}LAST_MONTH`}
                      key={"0"}
                      name="LAST MONTH"
                      className="pc_medium"
                      style={{
                        color: "#6F6F6F",
                        fontSize: "14px",
                        margin: 0,
                        textTransform: "uppercase",
                        letterSpacing: 0,
                        lineHeight: 1,
                        padding: "0px 17px",
                        marginBottom: 8,
                      }}
                    ></H1>
                  )}
                  <ViewCard
                    parent_id={"patient_dashboard" + i}
                    patientDetails={this.state.patientDetails}
                    openView={this.openView}
                    val={val}
                    key={i}
                    isShowLabel
                    noBookNow={
                      !(permission?.write?.indexOf("bookAppoinment") > -1)
                    }
                    nomodify={
                      !(permission?.write?.indexOf("modifyAppoinment") > -1)
                    }
                    nocancel={
                      !(permission?.write?.indexOf("cancleAppoinment") > -1)
                    }
                    showBtn
                    past
                    isDiabled={
                      this.state.personDetails?.isdeceased?.display ===
                        "Deceased" || !this.state?.personDetails?.active
                    }
                    handlesidebarCancelbooking={this.handlesidebarCancelbooking}
                  />
                </>
              )
            ),
        });
      }
    }
    return constructCard;
  };

  upcomingVisitCradConstruction = () => {
    const { permission } = this.state;
    let constructCard = [];
    if (
      this.props?.appointments?.data?.upcomingAppointmentNextMonth?.length ===
        0 &&
      this.props?.appointments?.data?.upcomingAppointmentNextWeek?.length ===
        0 &&
      this.props?.appointments?.data?.upcomingAppointmentThisMonth?.length ===
        0 &&
      this.props?.appointments?.data?.upcomingAppointmentThisWeek?.length ===
        0 &&
      this.props?.appointments?.data?.upcomingAppointmentToday?.length === 0
    ) {
      constructCard.push({
        id: 1,
        name: "Empty",
        component: <EmptyCard parent_id={"patient_dashboard"} />,
      });
    } else {
      if (
        this.props?.appointments?.data?.upcomingAppointmentToday?.length > 0
      ) {
        constructCard.push({
          id: 2,
          name: "Today",
          component:
            this.props?.appointments?.data?.upcomingAppointmentToday.map(
              (val, i) => (
                <>
                  {i === 0 && (
                    <H1
                      id={`${i}TODAY`}
                      key={"0"}
                      name="TODAY"
                      className="pc_medium"
                      style={{
                        color: "#6F6F6F",
                        fontSize: "14px",
                        margin: 0,
                        textTransform: "uppercase",
                        letterSpacing: 0,
                        lineHeight: 1,
                        padding: "0px 17px",
                        marginBottom: 8,
                      }}
                    ></H1>
                  )}
                  <ViewCard
                    parent_id={"patient_dashboard" + i}
                    patientDetails={this.state.patientDetails}
                    openView={this.openView}
                    val={val}
                    key={i}
                    isDiabled={
                      this.state.personDetails?.isdeceased?.display ===
                        "Deceased" || !this.state?.personDetails?.active
                    }
                    noBookNow={
                      !(permission?.write?.indexOf("bookAppoinment") > -1)
                    }
                    nomodify={
                      !(permission?.write?.indexOf("modifyAppoinment") > -1)
                    }
                    nocancel={
                      !(permission?.write?.indexOf("cancleAppoinment") > -1)
                    }
                    handlesidebarCancelbooking={this.handlesidebarCancelbooking}
                    loggedUserInfo={this.props?.loggedUserInfo}
                  />
                </>
              )
            ),
        });
      }
      if (
        this.props?.appointments?.data?.upcomingAppointmentThisWeek?.length > 0
      ) {
        constructCard.push({
          id: 3,
          name: "This Week",
          component:
            this.props?.appointments?.data?.upcomingAppointmentThisWeek.map(
              (val, i) => (
                <>
                  {i === 0 && (
                    <H1
                      id={`${i}_THIS_WEEK`}
                      key={"0"}
                      name="THIS WEEK"
                      className="pc_medium"
                      style={{
                        color: "#6F6F6F",
                        fontSize: "14px",
                        margin: 0,
                        textTransform: "uppercase",
                        letterSpacing: 0,
                        lineHeight: 1,
                        padding: "0px 17px",
                        marginBottom: 8,
                      }}
                    ></H1>
                  )}
                  <ViewCard
                    parent_id={"patient_dashboard" + i}
                    patientDetails={this.state.patientDetails}
                    openView={this.openView}
                    val={val}
                    key={i}
                    isDiabled={
                      this.state.personDetails?.isdeceased?.display ===
                        "Deceased" || !this.state?.personDetails?.active
                    }
                    noBookNow={
                      !(permission?.write?.indexOf("bookAppoinment") > -1)
                    }
                    nomodify={
                      !(permission?.write?.indexOf("modifyAppoinment") > -1)
                    }
                    nocancel={
                      !(permission?.write?.indexOf("cancleAppoinment") > -1)
                    }
                    handlesidebarCancelbooking={this.handlesidebarCancelbooking}
                    loggedUserInfo={this.props?.loggedUserInfo}
                  />
                </>
              )
            ),
        });
      }
      if (
        this.props?.appointments?.data?.upcomingAppointmentNextWeek?.length > 0
      ) {
        constructCard.push({
          id: 4,
          name: "Next Week",
          component:
            this.props?.appointments?.data?.upcomingAppointmentNextWeek.map(
              (val, i) => (
                <>
                  {i === 0 && (
                    <H1
                      id={`${i}NEXT_WEEK`}
                      key={"0"}
                      name="NEXT WEEK"
                      className="pc_medium"
                      style={{
                        color: "#6F6F6F",
                        fontSize: "14px",
                        margin: 0,
                        textTransform: "uppercase",
                        letterSpacing: 0,
                        lineHeight: 1,
                        padding: "0px 17px",
                        marginBottom: 8,
                      }}
                    ></H1>
                  )}
                  <ViewCard
                    parent_id={"patient_dashboard" + i}
                    patientDetails={this.state.patientDetails}
                    openView={this.openView}
                    val={val}
                    key={i}
                    isDiabled={
                      this.state.personDetails?.isdeceased?.display ===
                        "Deceased" || !this.state?.personDetails?.active
                    }
                    noBookNow={
                      !(permission?.write?.indexOf("bookAppoinment") > -1)
                    }
                    nomodify={
                      !(permission?.write?.indexOf("modifyAppoinment") > -1)
                    }
                    nocancel={
                      !(permission?.write?.indexOf("cancleAppoinment") > -1)
                    }
                    handlesidebarCancelbooking={this.handlesidebarCancelbooking}
                    loggedUserInfo={this.props?.loggedUserInfo}
                  />
                </>
              )
            ),
        });
      }
      if (
        this.props?.appointments?.data?.upcomingAppointmentThisMonth?.length > 0
      ) {
        constructCard.push({
          id: 5,
          name: "This Month",
          component:
            this.props?.appointments?.data?.upcomingAppointmentThisMonth.map(
              (val, i) => (
                <>
                  {i === 0 && (
                    <H1
                      id={`${i}THIS_MONTH`}
                      key={"0"}
                      name="THIS MONTH"
                      className="pc_medium"
                      style={{
                        color: "#6F6F6F",
                        fontSize: "14px",
                        margin: 0,
                        textTransform: "uppercase",
                        letterSpacing: 0,
                        lineHeight: 1,
                        padding: "0px 17px",
                        marginBottom: 8,
                      }}
                    ></H1>
                  )}
                  <ViewCard
                    parent_id={"patient_dashboard" + i}
                    patientDetails={this.state.patientDetails}
                    openView={this.openView}
                    val={val}
                    key={i}
                    isDiabled={
                      this.state.personDetails?.isdeceased?.display ===
                        "Deceased" || !this.state?.personDetails?.active
                    }
                    noBookNow={
                      !(permission?.write?.indexOf("bookAppoinment") > -1)
                    }
                    nomodify={
                      !(permission?.write?.indexOf("modifyAppoinment") > -1)
                    }
                    nocancel={
                      !(permission?.write?.indexOf("cancleAppoinment") > -1)
                    }
                    handlesidebarCancelbooking={this.handlesidebarCancelbooking}
                    loggedUserInfo={this.props?.loggedUserInfo}
                  />
                </>
              )
            ),
        });
      }
      if (
        this.props?.appointments?.data?.upcomingAppointmentNextMonth?.length > 0
      ) {
        constructCard.push({
          id: 6,
          name: "Next Month",
          component:
            this.props?.appointments?.data?.upcomingAppointmentNextMonth.map(
              (val, i) => (
                <>
                  {i === 0 && (
                    <H1
                      id={`${i}_NEXT_MONTH`}
                      key={"0"}
                      name="NEXT MONTH"
                      className="pc_medium"
                      style={{
                        color: "#6F6F6F",
                        fontSize: "14px",
                        margin: 0,
                        textTransform: "uppercase",
                        letterSpacing: 0,
                        lineHeight: 1,
                        padding: "0px 17px",
                        marginBottom: 8,
                      }}
                    ></H1>
                  )}
                  <ViewCard
                    parent_id={"patient_dashboard" + i}
                    patientDetails={this.state.patientDetails}
                    openView={this.openView}
                    val={val}
                    key={i}
                    isDiabled={
                      this.state.personDetails?.isdeceased?.display ===
                        "Deceased" || !this.state?.personDetails?.active
                    }
                    noBookNow={
                      !(permission?.write?.indexOf("bookAppoinment") > -1)
                    }
                    nomodify={
                      !(permission?.write?.indexOf("modifyAppoinment") > -1)
                    }
                    nocancel={
                      !(permission?.write?.indexOf("cancleAppoinment") > -1)
                    }
                    handlesidebarCancelbooking={this.handlesidebarCancelbooking}
                    loggedUserInfo={this.props?.loggedUserInfo}
                  />
                </>
              )
            ),
        });
      }
    }
    return constructCard;
  };

  render() {
    const {
      sidebar,
      activeId,
      cancelbooking,
      cancelbookingreason,
      cancelbookings,
      personDetails,
      permission,
    } = this.state;
    return (
      <Div
        id="patient_dashboard_parent_div"
        style={{ backgroundColor: "#F4F5F8" }}
      >
        {/* {this.state.editOpen ? 
                <PatientRegistration 
                    parent_edit_id={'patient_edit_dashboard'} 
                    editPatientDetails={this.state.patientDetails} handleEditForm={this.handleEdit}/> 
                : */}
        <TopHeader
          parent_id={"patient_dashboard"}
          info={this.state.loggedInState}
          // noHome
          // middleComponent={
          //     <PatientCard
          //         parent_id={'patient_dashboard'}
          //         patientDetails={this.state.patientDetails}
          //         info={this.state.loggedInState}
          //         // isAddBtn
          //         memberList={this.state.choosemember}
          //         addMember={this.toggleSideBar}
          //     />
          // }
        />
        <div
          style={{
            display: this.state.loading ? "flex" : "block",
            justifyContent: "center",
            alignItems: "center",
            height: this.state.loading ? "calc(100vh - 176px)" : "auto",
          }}
        >
          {/* //(this.props.location?.state?.unregister) */}
          <LoadingComp loading={this.state.loading ? true : false}>
            {!this.state.callcen && (
              <Grid
                id="patient_dashboard_patientCardGrid"
                container
                spacing={2}
                style={{
                  padding: "10px 20px",
                  backgroundImage:
                    "linear-gradient(to right, #F7ECF4, #DAEAF8)",
                }}
              >
                <Grid item xs={6} id="patient_dashboard_grid_patientCard">
                  <PatientCard
                    permissionData={this.state?.permissionData}
                    nameDetails={makeName(
                      this.state.personDetails?.name?.[0] || {}
                    )}
                    parent_id={"patient_dashboard"}
                    patientDetails={this.state.patientDetails}
                    mrnVal={this.state?.personDetails?.alias}
                    notOpen
                    info={this.state.loggedInState}
                    age={
                      _calculateAge(
                        new Date(
                          moment(
                            this.state.patientDetails.birthDay,
                            "DD/MM/YYYY"
                          )
                        )
                      ) + " Yrs"
                    }
                    // isAddBtn
                    memberList={this.state.choosemember}
                    addMember={this.toggleSideBar}
                    active={personDetails?.active}
                    activeData={
                      this.state.personDetails?.isdeceased?.display &&
                      this.state.personDetails.active &&
                      this.state.personDetails?.isdeceased?.display
                    }
                    noedit={!(permission?.write?.indexOf("modifyPatient") > -1)}
                    handleEdit={this.handleEdit}
                  />
                </Grid>
                <Grid item xs={6} id="patient_dashboard_grid_patientTypeCard">
                  <PatientCareTypeCard
                    parent_id={"Referral_Registration"}
                    careList={[...this.state.fin_data]}
                    selected={this.state.selectOption}
                    padding
                    // onChange={(data) => this.changeState('selectOption', data)}
                  />
                </Grid>
              </Grid>
            )}

            <div
              style={{
                height: "calc(100vh - 176px)", // 176px = 72px + 104px // 72 - topheader , 104 - PatientDashboardNavBar
                overflow: "auto",
              }}
            >
              <PatientDashboardNavBar
                navBarArr={[
                  { label: "Schedule & Visits", id: 1 },
                  // { label: "Bill Info", id: 2 },
                ]}
                disabled={
                  this.state.patientDetails?.allData?.Patientname?.length !==
                    0 &&
                  (this.state.personDetails?.isdeceased?.display ===
                    "Deceased" ||
                    !this.state?.personDetails?.active) &&
                  !this.state.callcen
                  // && this.state.personDetails.active
                }
                permissionData={this.state?.permissionData}
                btnName={
                  this.state?.permissionData?.write?.indexOf(
                    "patientBannerEditIcon"
                  ) > -1
                    ? "Book New Appointment / Walk-In"
                    : "Book New Appointment"
                }
                enablebtn={permission?.write?.indexOf("bookAppoinment") > -1}
                clickNav={this.setActiveId}
                activeId={activeId}
                btnClick={this.btnClick}
                parent_id={"patient_dashboard"}
              />
              {activeId === 1 && !this.props?.appointments?.loading && (
                <React.Fragment>
                  <div
                    style={{
                      height: "calc(100vh - 136px)",
                      // overflow:'hidden',
                      padding: "14px 37px",
                    }}
                  >
                    {/* <Row
									id="patient_dashboard_upcoming_row"
									key={"0"}
									alignItems="stretch"
									direction="row"
									justify="flex-start"
									spacing="2"
									inLineStyles={{
										// margin: "14px !important",
										border: "1px solid #F0F0F0",
										borderRadius: "8px",
										// minHeight: "72vh",
										backgroundColor: "#F9F9F9",
										padding: "15px 7px",
										height: "100%",
										overflow: "hidden",
									}}
								> */}
                    <Row
                      id="patient_dashboard_upcoming_row"
                      key={"0"}
                      alignItems="stretch"
                      direction="row"
                      justify="flex-start"
                      spacing="2"
                      inLineStyles={{
                        // margin: "14px !important",
                        // border: "1px solid #F0F0F0",
                        borderRadius: "8px",
                        // minHeight: "72vh",
                        backgroundColor: "#F4F5F8",
                        // padding: "15px 7px",
                        height: "100%",
                        overflow: "auto",
                      }}
                    >
                      {
                        <Col
                          key={"0"}
                          name="qdm-col-12"
                          alignItems="stretch"
                          direction="row"
                          justify="flex-start"
                          textAlign="left"
                          lg={permission?.read?.length === 2 ? "6" : "4"}
                          xs="12"
                          md={permission?.read?.length === 2 ? "6" : "4"}
                          sm={permission?.read?.length === 2 ? "6" : "4"}
                          style={{
                            // borderRight: "1px solid #E0E0E0",
                            padding: 0,
                            display:
                              permission?.read?.indexOf("upcoming") > -1
                                ? "block"
                                : "none",
                            overflow: "auto",
                            height: "100%",
                            backgroundColor: "#fff",
                            maxWidth: "31%",
                            marginRight: "2%",
                          }}
                        >
                          <CardList
                            parent_id={"patient_dashboard"}
                            title="UPCOMING"
                            middleComponent={
                              <>
                                {this.upcomingVisitCradConstruction().map(
                                  (data) => {
                                    return data.component;
                                  }
                                )}
                              </>
                            }
                          />
                        </Col>
                      }
                      {permission?.read?.indexOf("followUpReminder") > -1 && (
                        <Col
                          key={"0"}
                          name="qdm-col-12"
                          alignItems="stretch"
                          direction="row"
                          justify="flex-start"
                          textAlign="left"
                          lg={permission?.read?.length === 2 ? "6" : "4"}
                          xs="12"
                          md={permission?.read?.length === 2 ? "6" : "4"}
                          sm={permission?.read?.length === 2 ? "6" : "4"}
                          style={{
                            // borderRight: "1px solid #E0E0E0",
                            padding: 0,
                            overflow: "auto",
                            height: "100%",
                            backgroundColor: "#fff",
                            maxWidth: "31%",
                            marginRight: "2%",
                          }}
                        >
                          <CardList
                            parent_id={"patient_dashboard"}
                            title="FOLLOW UP REMINDER"
                            middleComponent={
                              this.state?.followUpAppointmentData?.length >
                              0 ? (
                                this.state?.followUpAppointmentData.map(
                                  (val, i) => (
                                    <ViewCard
                                      parent_id={"patient_dashboard" + i}
                                      patientDetails={this.state.patientDetails}
                                      openView={this.openView}
                                      showBtn={true}
                                      isFollowUp={true}
                                      val={val}
                                      isDiabled={
                                        this.state.personDetails?.isdeceased
                                          ?.display === "Deceased" ||
                                        !this.state?.personDetails?.active
                                      }
                                      noBookNow={
                                        !(
                                          permission?.write?.indexOf(
                                            "bookAppoinment"
                                          ) > -1
                                        )
                                      }
                                      nomodify={
                                        !(
                                          permission?.write?.indexOf(
                                            "modifyAppoinment"
                                          ) > -1
                                        )
                                      }
                                      nocancel={
                                        !(
                                          permission?.write?.indexOf(
                                            "cancleAppoinment"
                                          ) > -1
                                        )
                                      }
                                      key={i}
                                      handlesidebarCancelbooking={
                                        this.handlesidebarCancelbooking
                                      }
                                    />
                                  )
                                )
                              ) : (
                                <EmptyCard parent_id={"patient_dashboard"} />
                              )
                            }
                          />
                        </Col>
                      )}
                      {permission?.read?.indexOf("visits") > -1 && (
                        <Col
                          key={"0"}
                          name="qdm-col-12"
                          alignItems="stretch"
                          direction="row"
                          justify="flex-start"
                          textAlign="left"
                          lg={permission?.read?.length === 2 ? "6" : "4"}
                          xs="12"
                          md={permission?.read?.length === 2 ? "6" : "4"}
                          sm={permission?.read?.length === 2 ? "6" : "4"}
                          style={{
                            padding: 0,
                            overflow: "auto",
                            height: "100%",
                            backgroundColor: "#fff",
                          }}
                        >
                          {/* {console.log('yess',this.props?.appointments?.data?.currentVisist)} */}
                          <CardList
                            parent_id={"patient_dashboard"}
                            title="CURRENT VISIT"
                            middleComponent={
                              // this.state.personDetails?.isdeceased?.display !== "Deceased" &&
                              this.props?.appointments?.data?.currentVisist
                                ?.length > 0 ? (
                                this.props?.appointments?.data?.currentVisist.map(
                                  (val, i) => (
                                    <ViewCard
                                      parent_id={"patient_dashboard" + i}
                                      patientDetails={this.state.patientDetails}
                                      openView={this.openView}
                                      val={val}
                                      key={i}
                                      canceled={!val?.status}
                                      // isShowLabel
                                      showBtn={!val?.status}
                                      isDiabled={
                                        this.state.personDetails?.isdeceased
                                          ?.display === "Deceased" ||
                                        !this.state?.personDetails?.active
                                      }
                                      noBookNow={
                                        !(
                                          permission?.write?.indexOf(
                                            "bookAppoinment"
                                          ) > -1
                                        )
                                      }
                                      nomodify={
                                        !(
                                          permission?.write?.indexOf(
                                            "modifyAppoinment"
                                          ) > -1
                                        )
                                      }
                                      nocancel={
                                        !(
                                          permission?.write?.indexOf(
                                            "cancleAppoinment"
                                          ) > -1
                                        )
                                      }
                                      // past
                                      visitCard
                                      handlesidebarCancelbooking={
                                        this.handleVisit
                                      }
                                    />
                                  )
                                )
                              ) : (
                                <EmptyCard parent_id={"patient_dashboard"} />
                                // <p>Hi</p>
                              )
                            }
                          />
                          <br />
                          <CardList
                            parent_id={"patient_dashboard"}
                            title="PAST"
                            middleComponent={
                              <>
                                {this.pastVisitCradConstruction().map(
                                  (data) => {
                                    return data.component;
                                  }
                                )}
                              </>
                            }
                          />
                        </Col>
                      )}
                    </Row>
                    {/* </Row> */}
                  </div>
                </React.Fragment>
              )}
              {activeId === 2 && (
                <React.Fragment>
                  <Div
                    id="patient_dashboard_Pay_Selected_Bills_div"
                    // spacing="2"
                    inLineStyles={{
                      margin: "14px !important",
                      border: "1px solid #F0F0F0",
                      borderRadius: "8px",
                      minHeight: "72vh",
                      backgroundColor: "#F9F9F9",
                      padding: 20,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <BillNav
                      parent_id={"patient_dashboard"}
                      activeid={1}
                      btnList={[
                        { id: 1, label: "Outstanding" },
                        { id: 2, label: "Paid" },
                      ]}
                    />
                    <Div
                      id="patient_dashboard_tablelist_div"
                      style={{ flex: 1 }}
                    >
                      <Paper
                        id="patient_dashboard_tablelist_paper"
                        style={{
                          margin: 0,
                          padding: "20px 0px",
                          boxShadow: "0px 10px 25px #0000000A",
                          borderRadius: 8,
                          border: "1px solid #F0F0F0",
                        }}
                      >
                        <TableList
                          parent_id={"patient_dashboard"}
                          tableHeader={tableHeader}
                          tableData={[]}
                          isSerialNo={true}
                          isCheckBox={true}
                          tableDataList={[]}
                          loading={false}
                          isempty={false}
                        />
                      </Paper>
                    </Div>
                    <Div
                      id="patient_dashboard_Pay_Selected_Bills_div"
                      style={{ textAlign: "center" }}
                    >
                      <Button
                        id="patient_dashboard_Pay_Selected_Bills_buttom"
                        style={this.containedButton}
                        onClick={() => this.selectPayBills()}
                      >
                        Pay Selected Bills
                      </Button>
                    </Div>
                  </Div>
                </React.Fragment>
              )}
            </div>

            <ModelComponent
              parent_id={"patient_dashboard"}
              hiddencloseIcon={true}
              open={this.state.open}
              onClose={this.openView}
              childrens={
                <ConfirmationPage
                  parent_id={"patient_dashboard"}
                  appointmentId={this.state.appointmentId}
                  appointmentDetails={true}
                  hidden={true}
                />
                // <ConfirmBook
                //     appointmentId={this.state.appointmentId}
                //     appointmentDetails={true}
                //     hidden={true}
                // />
              }
              width={530}
              backgroundColorNone
            />
            <SideMenu
              id="patient_dashboard_cancel_sidemenu"
              open={cancelbooking}
              direction="right"
              width={440}
              color="default"
              onClose={() => this.handlesidebarCancelbooking("closing")}
              style={{ padding: 0 }}
            >
              <CancelBookingPage
                parent_id={"patient_dashboard"}
                cancelbookingreason={cancelbookingreason}
                cancelbookings={cancelbookings}
                appointmentId={this.state.appointmentId}
                handleSelectCancelbooking={this.handleSelectCancelbooking}
                handlecahngeCancelbooking={this.handlecahngeCancelbooking}
              />
            </SideMenu>
            <SideMenu
              id="patient_dashboard_sidemenu"
              open={sidebar}
              direction="right"
              width={445}
              color="default"
              onClose={() => this.toggleSideBar()}
            >
              {this.state.searchMenber ? (
                <Addmember
                  parent_id={"patient_dashboard"}
                  name={"Add Member"}
                  nextSection={(member, v, l) => this.nextSection(member, v, l)}
                  handelChangeSelect={(v) => this.handelChangeSelect(v)}
                  signUpData={this.state.signUpData}
                  chooseSearch={this.state.chooseSearch}
                  addMember
                  memberIds={this.state.memberIds}
                />
              ) : this.state.memberForm ? (
                <Addmemberform
                  parent_id={"patient_dashboard"}
                  addNewMember
                  changeSideBar={this.changeSideBar}
                  signUpData={this.state.signUpData}
                  nextSection={(member, signUpInfo) =>
                    this.nextSection(member, signUpInfo)
                  }
                />
              ) : this.state.memberOtp ? (
                <Addmemberotp
                  parent_id={"patient_dashboard"}
                  addmmber={this.state.addmmber}
                  nextSection={(member, v) => this.nextSection(member, v)}
                  signUpData={this.state.signUpData}
                  appointmentDetails={true}
                />
              ) : null}
              {this.state.memberSuccessPage ? (
                <AppointmentAddMemberSuccessPage
                  parent_id={"patient_dashboard"}
                  addmmber={this.state.addmmber}
                  memeberIds={this.state.memberIds}
                  signUpData={this.state.signUpData}
                  nextSection={(member, v, l) => this.nextSection(member, v, l)}
                  toggleSideBar={(member) => this.toggleSideBar(member)}
                />
              ) : null}
            </SideMenu>
            <SideMenu
              id="patient_dashboard_BookAppoinment_sidemenu"
              open={this.state.isBookAppoinment}
              direction="right"
              width={435}
              color="default"
              onClose={() => this.setValue("isBookAppoinment", false)}
            >
              <RefferalConfirm
                parent_id={"patient_dashboard"}
                primaryMember={this.state.primaryMember}
                {...this.props}
              />
            </SideMenu>
          </LoadingComp>
        </div>
      </Div>
    );
  }
}

PatientDashBoard.contextType = AlertContext;

const mapStateToProps = (state) => ({
  appointments: state?.appointmentReducer?.allAppointments,
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
});

export default connect(
  mapStateToProps,
  actions
)(withAllContexts(withRouter(PatientDashBoard)));
