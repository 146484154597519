import React from 'react';
import { Div, Button, H6 } from 'qdm-component-library';
import { UIColor } from '../../utils';

export const Footer = (props) => {

    const styles = {
        flexCenter: {
            display: 'flex',
            alignItems: 'center'
        },
        wrapper: {
            width: '98%',
            position: 'fixed',
            bottom: '0px',
            backgroundColor:"white",
            padding:"10px",
            margin:"15px",
            borderRadius:"10px",
            border:`1px solid ${UIColor?.lineBorderFill?.color}`
        },
        backButton: {
            backgroundColor: "#F0F0F0",
            color: '#323232',
            fontFamily: 'pc_medium',
            fontSize: 14,
            borderRadius: 8,
            padding: '7px 16px'
        },
        signOffButton: {
            fontSize: 14,
            borderRadius: 8,
            padding: '7px 16px',
            fontFamily: 'pc_medium',
            backgroundColor: UIColor.primaryColor.color,
            borderColor:  UIColor.primaryColor.color,
        },
        eSign: {
            color: '#101010',
            fontSize: 14,
            borderBottom: '2px solid #E0E0E0',
            padding: '0px 12px 7px 12px',
            margin: 0,
            marginRight: 20,
            letterSoacing: 0
        }
    }
    const { parent_id } = props
    return (
        <div
            id={`${parent_id}_footer_parent_div`}
            style={styles.wrapper}>
            <Div
                id={`${parent_id}_footer_parent_sub_div`}
                style={styles.flexCenter}>
                {
                    props?.goBackButton &&
                    <Div
                        id={`${parent_id}_footer_button_div`}
                        style={{ flex: 1 }}>
                        <Button
                            id={`${parent_id}_footer_goback_button`}
                            onClick={() => props?.goBackButtonAction && props?.goBackButtonAction()}
                            variant={"text"}
                            inLineStyles={styles.backButton}
                        >
                            {props?.goBackButton}
                        </Button>
                    </Div>
                }

                <Div
                    id={`${parent_id}_footer_confrim_button_div`}
                    style={styles.flexCenter}>
                    {
                        props?.eSignature &&
                        <H6
                            id={`${parent_id}_footer_E-signature_h6`}
                            className="pc_regular" style={styles.eSign}>
                            E-signature
                        </H6>
                    }
                    {
                        props?.confirmButton &&
                        <Button
                            id={`${parent_id}_footer_confirm_button`}
                            onClick={() => props?.confirmButtonAction && props?.confirmButtonAction()}
                            inLineStyles={styles.signOffButton}
                        >
                            {props?.confirmButton}
                        </Button>
                    }
                </Div>
            </Div>
        </div>
    )
}

Footer.defaultProps = {
    goBackButton: "Go back",
    goBackButtonAction: null,
    confirmButton: "Confirm",
    confirmButtonAction: null,
    eSignature: true
}