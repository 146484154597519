import React from "react";
import {
	Div,
	Paper,
	Select,
	H6,
	Col,
	Row,
	TextInput,
	Text,
} from "qdm-component-library";
import "./style.css";
import { withAllContexts } from "../../HOCs";
import { UIColor, ValidateEmail } from "../../utils";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Typography } from "@material-ui/core";
class PatientCotactPoint extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	setDetails = (name, value, limit) => {
		if (limit) {
			if (limit < value.length) {
				return false;
			}
		}
		let patientData = this.props[this.props.saveto];

		if (name === "mode") {
			patientData.contact["mobile"] = "";
		}

		if (patientData.contact?.error) {
			if (name === "mobile") {
				if (this.label() === "Phone") {
					patientData.contact.error[name] = value.length === 10 ? false : true;
				} else if (this.label() === "E-mail" || this.label() === "Email") {
					ValidateEmail(value)
						? (patientData.contact.error[name] = false)
						: (patientData.contact.error[name] = true);
				} else {
					patientData.contact.error[name] = false;
				}
			} else {
				patientData.contact.error[name] = false;
			}
		}
		patientData.contact[name] = value;
		patientData[this.props.setTo]({
			...patientData,
		});
	};

	newRowAdd = () => {
		// let patientData = this.props[this.props.saveto]
		// patientData.names.push({})
		// patientData[this.props.setTo]({
		// 		...patientData,
		// })
	}

	styles = {
		labelStyle: {
			color: "#6F6F6F",
			fontSize: "12px",
			marginBottom: "6px",
			fontFamily: `pc_regular !important`,
		},
		borderStyle: {
			borderRadius: 8,
			borderColor: "#E0E0E0",
		},
		 errorborderStyle:{
            borderRadius: 8,
            borderColor: 'red',
        },
	};

	FlterOptions = (type, sort) => {
		const { allMasters } = this.props;
		var lista = [];
		const data = allMasters?.[type];
		for (let index in data) {
			var datatemp = {
				value: data[index]?.coding?.display,
				_id: data[index]?.coding?._id,
			};
			lista.push(datatemp);
		}
		if(sort) lista.sort((a,b) => sort === 'asc' ? b.value - a.value : a.value - b.value);
		return lista;
	};

	label = () => {
		let { contact } = this.props[this.props.saveto];

		if (contact?.mode?.value?.toLowerCase?.() === "phone") {
			return "Phone";
		} else if (contact?.mode?.value?.toLowerCase?.() === "fax") {
			return "Fax";
		} else if (
			contact?.mode?.value?.toLowerCase?.() === "email" ||
			contact?.mode?.value?.toLowerCase?.() === "e-mail"
		) {
			return "Email";
		} else if (contact?.mode?.value?.toLowerCase() === "url") {
			return "Url";
		} else if (contact?.mode?.value?.toLowerCase() === "sms") {
			return "SMS";
		} else if (contact?.mode?.value?.toLowerCase() === "other") {
			return "Other";
		} else if (contact?.mode?.value?.toLowerCase() === "pager") {
			return "Pager";
		} else {
			return "Mobile";
		}
	};

	contactModeHelperText = (contact) => {
		if (contact?.error && contact?.error["mobile"]) {
			if (contact.mobile && this.label() === "Phone") {
				return "Enter valid mobile number";
			} else if (
				(contact.mobile && this.label() === "Email") ||
				(contact.mobile && this.label() === "E-mail")
			) {
				return "Enter valid Email ID";
			} else {
				return "";
			}
		}
	};

	render() {
		let { contact,statusDetails } = this.props[this.props.saveto];
		const { labelStyle, borderStyle,errorborderStyle } = this.styles;
		const { parent_id } = this.props;

		return (
			<Div id={`${parent_id}_contact_point_parent_div`} className="pi-root">
				<Paper
					id={`${parent_id}_contact_point_parent_paper`}
					className="pi-paper"
				>
					<Div id={`${parent_id}_contact_point_title_label_div`}>
						<H6
							id={`${parent_id}_contact_point_title_label_h6`}
							className="pi-title"
						>
							CONTACT POINT
						</H6>
						{
							false &&
							<Text
								id={`${parent_id}_patient_name_label_addnew_text`}
								onClick={() => this.newRowAdd()} className="pi-addbtn">+ Add New
							</Text>
						}
					</Div>
					<Row
						id={`${parent_id}_contact_point_parent_row`}
						className="pi-actions-root"
					>
						<Col
							id={`${parent_id}_contact_point_contact_mode_col`}
							md={3}
							lg={3}
							sm={6}
							xs={12}
							className="no-padding-left"
						>
							<Select
								id={`${parent_id}_contact_point_contact_mode_label_select`}
								showArrow
								label="Contact Mode"
								placeholder="select..."
								labelStyle={labelStyle}
								inLineStyles={contact?.error && contact?.error["mode"] ? errorborderStyle :borderStyle}
								hoverColor={UIColor.primaryColor.color}
								isRequired
								options={this.FlterOptions("CONTACTSYSTEM")}
								disabled = {(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
								// options={[
								//     { value: 'Phone', label: 'Phone' },
								//     { value: 'Fax', label: 'Fax' },
								//     { value: 'E-mail', label: 'E-mail' },
								//     { value: 'url', label: 'url' },
								//     { value: 'SMS', label: 'SMS' },
								//     { value: 'other', label: 'other' },
								// ]}
								value={contact?.mode?.value ?? null}
								onChangeValue={(e) => this.setDetails("mode", e)}
								// helperText={
								// 	contact?.error && contact?.error["mode"] ? "Is required" : ""
								// }
								error={contact?.error && contact?.error["mode"] ? true : false}
							/>
						</Col>
						{this.label() === "Phone" && (
							<Col
								id={`${parent_id}_contact_point_contact_number_col`}
								md={2}
								lg={2}
								sm={6}
								xs={12}
								className="no-padding-left"
							>
								<Typography className="pc_regular" style={{...labelStyle, margin: "0px 0px 5px 0px"}}>Country Code <span style={{color:"red"}}>*</span></Typography>
								<Autocomplete
									id="combo-box-demo"
									options={this.props.Sbi}
									getOptionLabel={(option) => option.value}
									size={"small"}
									isRequired={true}
									disabled = {(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
									renderInput={(params) => (
										<TextField 
											{...params} 
											error={contact?.error && (contact?.error["mobile_code"] || contact?.error["code_err"]) ? true : false}
											style={contact?.error && (contact?.error["mobile_code"] || contact?.error["code_err"]) ? errorborderStyle : borderStyle} 
											variant="outlined" 
										/>
									)}
									onChange={(e, value) => this.setDetails("mobile_code", value)}
									value={contact.mobile_code ? contact.mobile_code : ""}
								/>
								{/* <Select
                            id={`${parent_id}_contact_point_contact_number_select`}
                            value={contact?.mobilecode?.value ?? null}
                            options={this.props.Sbi}
                            // options={[
                            //     { value: '+91', label: '+91' },
                            // ]}
                            onChangeValue={(e) => this.setDetails("mobilecode", e)}
                            placeholder={this.label()}
                            abelStyle={labelStyle} style={borderStyle}
                            showArrow
							label="Country Code"
                            placeholder="select..."
                            labelStyle={labelStyle}
                            inLineStyles={borderStyle}
                            hoverColor={UIColor.primaryColor.color}
                        />
                    </Col>}
                    <Col
                        id={`${parent_id}_contact_point_mobile_number_col`}
                        md={3} lg={3} sm={6} xs={12} className="no-padding-left">
                        <TextInput
                            id={`${parent_id}_contact_point_mobile_number_textinput`}
                            type={((this.label() === 'Mobile') || (this.label() === 'Phone')) ? 'number' : 'type'}
                            // helperText={(contact?.error && contact?.error['mobile']) ? 'Is required' : ''}
                            helperText={this.contactModeHelperText(contact)}
                            value={contact.mobile ? contact.mobile : ""}
                            onChange={(e) => this.setDetails("mobile", e.target.value, ((this.label() === 'Mobile') || (this.label() === 'Phone')) ? 10 : 15)}
                            error={(contact?.error && contact?.error['mobile']) ? true : false}
                            label={this.label()} labelStyle={labelStyle} style={borderStyle}
                            placeholder={this.label()}
                            isRequired={true}
                            // disabled={contact.mode ? false : true}
                            hoverColor={UIColor.primaryColor.color} />

                    </Col>
                    <Col
                        id={`${parent_id}_contact_point_use_label_col`}
                        md={1} lg={1} sm={6} xs={12} className="no-padding-left">
                        <Select
                            id={`${parent_id}_contact_point_use_label_select`}
                            showArrow
                            label="Use"
                            placeholder="select..."
                            labelStyle={labelStyle}
                            inLineStyles={borderStyle}
                            hoverColor={UIColor.primaryColor.color}
                            options={this.FlterOptions('ADDRESSUSE')}
                            // options={[
                            //     { value: 'Home', label: 'Home' },
                            //     { value: 'Work', label: 'Work' },
                            //     { value: 'Temp', label: 'Temp' },
                            //     { value: 'Old', label: 'Old' },
                            // ]}
                            value={contact?.use?.value ?? null}
                            onChangeValue={(e) => this.setDetails("use", e)}
                        />
                    </Col>
                    <Col
                        id={`${parent_id}_contact_point_priority_label_col`}
                        md={1} lg={1} sm={6} xs={12} className="no-padding-left">
                        <Select
                            id={`${parent_id}_contact_point_priority_label_select`}
                            showArrow
                            label="Priority"
                            placeholder="select..."
                            labelStyle={labelStyle}
                            inLineStyles={borderStyle}
                            hoverColor={UIColor.primaryColor.color}
                            options={this.FlterOptions('PRIORITY')}
                            // options={[
                            //     { value: '1', label: '1' },
                            //     { value: '2', label: '2' },
                            //     { value: '3', label: '3' },
                            //     { value: '4', label: '4' },
                            //     { value: '5', label: '5' },
                            //     { value: '6', label: '6' },
                            // ]}
                            value={contact?.priority?.value ?? null}
                            onChangeValue={(e) => this.setDetails("priority", e)}
                        />
                    </Col>
                </Row>
            </Paper>
        </Div>

    }
                        /> */}
							</Col>
						)}
						<Col
							id={`${parent_id}_contact_point_mobile_number_col`}
							md={3}
							lg={3}
							sm={6}
							xs={12}
							className="no-padding-left"
						>
							<TextInput
								id={`${parent_id}_contact_point_mobile_number_textinput`}
								type={
									this.label() === "Mobile" || this.label() === "Phone"
										? "number"
										: "type"
								}
								// helperText={(contact?.error && contact?.error['mobile']) ? 'Is required' : ''}
								disabled = {(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
								helperText={this.contactModeHelperText(contact)}
								value={contact.mobile ? contact.mobile : ""}
								onChange={(e) =>
									this.setDetails(
										"mobile",
										e.target.value,
										this.label() === "Mobile" || this.label() === "Phone"
											? 20
											: 40
									)
								}
								error={
									contact?.error && contact?.error["mobile"] ? true : false
								}
								label={this.label()}
								labelStyle={labelStyle}
								style={contact?.error && (contact?.error["mobile"] || contact?.error["number_err"]) ? errorborderStyle : borderStyle}
								placeholder={this.label()}
								isRequired={true}
								// disabled={contact.mode ? false : true}
								hoverColor={UIColor.primaryColor.color}
							/>
						</Col>
						<Col
							id={`${parent_id}_contact_point_use_label_col`}
							md={1}
							lg={1}
							sm={6}
							xs={12}
							className="no-padding-left"
						>
							<Select
								id={`${parent_id}_contact_point_use_label_select`}
								showArrow
								label="Use"
								placeholder="select..."
								labelStyle={labelStyle}
								inLineStyles={borderStyle}
								hoverColor={UIColor.primaryColor.color}
								options={this.FlterOptions("ADDRESSUSE")}
								// options={[
								//     { value: 'Home', label: 'Home' },
								//     { value: 'Work', label: 'Work' },
								//     { value: 'Temp', label: 'Temp' },
								//     { value: 'Old', label: 'Old' },
								// ]}
								value={contact?.use?.value ?? null}
								onChangeValue={(e) => this.setDetails("use", e)}
								disabled = {(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
							/>
						</Col>
						<Col
							id={`${parent_id}_contact_point_priority_label_col`}
							md={1}
							lg={1}
							sm={6}
							xs={12}
							className="no-padding-left"
						>
							<Select
								id={`${parent_id}_contact_point_priority_label_select`}
								showArrow
								label="Priority"
								placeholder="select..."
								labelStyle={labelStyle}
								inLineStyles={borderStyle}
								hoverColor={UIColor.primaryColor.color}
								options={this.FlterOptions("PRIORITY", "asc")}
								// options={[
								//     { value: '1', label: '1' },
								//     { value: '2', label: '2' },
								//     { value: '3', label: '3' },
								//     { value: '4', label: '4' },
								//     { value: '5', label: '5' },
								//     { value: '6', label: '6' },
								// ]}
								value={contact?.priority?.value ?? null}
								onChangeValue={(e) => this.setDetails("priority", e)}
								disabled = {(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
							/>
						</Col>
					</Row>
				</Paper>
			</Div>
		);
	}
}

export default withAllContexts(PatientCotactPoint);
