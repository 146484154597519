import React from "react";
import {
	Div,
	Col,
	Row,
	Paper,
	H6,
	TextInput,
	Select,
} from "qdm-component-library";
import "./style.css";
import { withQueue, withAllContexts } from "../../HOCs";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { AlertContext } from "../../contexts";
import { withRouter } from "react-router-dom";
import { makeName } from "../../utils";
function Pactitioner(props) {
	const styles = {
		labelStyle: {
			color: "#6F6F6F",
			fontSize: "12px",
			marginBottom: "6px",
			fontFamily: `pc_regular !important`,
		},
		borderStyle: {
			borderRadius: 8,
			border: "1px solid #E0E0E0",
			// background: '#F0F0F0'
		},
		errorBorder: {
			borderRadius: 8,
			border: "1px solid #f334466"
		}
	};

	const { practitioner } = props.visitData;
	const [practitioner_name, setpractitioner_name] = React.useState([]);
	const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
	const [practitioner_role, setpractitioner_role] = React.useState([]);
	const [getpractitioner_name_Role_data, setpractitioner_name_Role_data] =
		React.useState([]);

	const setDetails = async (name, value) => {
		let patientData = props.visitData;
		patientData.practitioner[name] = value;
		patientData.setVisitDetails({
			...patientData,
		});
		forceUpdate();
		const { appointmentData } = props;
		if (name === "speciality") {
			if (props.GET_PRACTITIONER) {
				const practitioner_name = await props.GET_PRACTITIONERS({
					OrgID: value?.codeable_id,
					org_id: appointmentData?.orgid?._id,
				});
				const practitioner_name_Data = practitioner_name?.payload?.data?.map(
					(v) => {
						return { value: makeName(v?.name?.[0]) };
					}
				);
				let type = [];
				const practitioner_role_Data = practitioner_name?.payload?.data
					?.map((v) => {
						let typeData = v?.practitioner_type?.[0]?.coding?.[0]?.display ?? "";
						if (type.indexOf(typeData) === -1) {
							type.push(typeData);
							return { value: typeData };
						}
					})
					.filter((r) => !!r);
				let name = [];
				const practitioner_name_Role_data = practitioner_name?.payload?.data
					?.map((v) => {
						let typeData =
							v?.practitioner_role?.[0]?.role?.[0]?.coding?.[0]?.display ?? "";
						if (name.indexOf(typeData) === -1) {
							name.push(typeData);
							return { value: typeData };
						}
					})
					.filter((r) => !!r);
				// const practitioner_name_Role = await props.GET_PRACTITIONER_ROLE({
				//   OrgID: value?.label,
				//   org_id: appointmentData?.healthservice?.[0]?.Organization?.[0]?._id
				// });
				// const practitioner_name_Role_data =
				//   practitioner_name_Role?.payload?.data?.map((v) => {
				//     return { value: v?.code?.[0]?.text };
				//   });
				setpractitioner_name(practitioner_name_Data);
				setpractitioner_role(practitioner_role_Data);
				setpractitioner_name_Role_data(practitioner_name_Role_data);
			}
		}
	};
	const FlterOptions = (type) => {
		const { allMasters } = props;
		var lista = [];
		const data = allMasters?.[type];
		for (let index in data) {
			var datatemp = {
				value: data[index]?.coding?.display,
				label: data[index]?.coding?.id,
				coding_id: data[index]?.coding?._id,
				codeable_id: data[index]?._id,
			};
			lista.push(datatemp);
		}
		return lista;
	};
	// const Practioner = () => {
	//     const { appointmentData } = props
	//     var arr = []
	//     const data = appointmentData?.Practioner?.filter(v => {
	//         return v?.roles.filter(l => {
	//             return l?.healthcareservices?.filter(s => {
	//                 returns?.category?.filter(l => {
	//                     return arr.push({ value: l?.display })

	//                 })
	//             })
	//         })
	//     })
	//     console.log(data)
	//     return arr.length > 0 ? arr : []
	// }

	const { labelStyle, borderStyle,errorBorder } = styles;

	const { walkin } = props;

	React.useEffect(() => {
		const { choosemember, appointmentData, walkin } = props;
		if (true) {
			if (appointmentData?.resourcetype?.toLowerCase() === "practitioner") {
				let patientData = props.visitData;
				patientData.practitioner["id_name"] = {
					value: makeName(appointmentData?.resourcecode?.name?.[0]),
				};
				patientData.setVisitDetails({
					...patientData,
				});
			}
		}
	}, [props.choosemember]);

	React.useEffect(() => {
		const { appointmentData, walkin } = props;
		// if (walkin) {
			// if (appointmentData?.Practioner) {
			let patientData = props.visitData;
			patientData.practitioner["clinic"] = {
				value: appointmentData?.orgid?.name,
			};
			patientData.setVisitDetails({
				...patientData,
			});
			// }
		// }

		if (appointmentData) {
			// if (appointmentData?.Practioner) {
				let patientData = props.visitData;
				let practrole = appointmentData?.resourcecode?.practitioner_role?.[0]?.role?.coding?.[0]?.display	|| "";
				patientData.practitioner["role"] = {
					value: practrole,
				};
				patientData.setVisitDetails({
					...patientData,
				});
			// }
		}

		if (appointmentData) {
			// if (appointmentData?.Practioner) {
				let patientData = props.visitData;
				let pracType = appointmentData?.resourcecode?.practitioner_type?.[0]?.coding?.[0]?.display || "";
				patientData.practitioner["type"] = {
					value: pracType,
				};
				patientData.setVisitDetails({
					...patientData,
				});
			// }
		}

		if (appointmentData) {
			let patientData = props.visitData;
			patientData.practitioner["speciality"] = {
				value: appointmentData?.Speciality?.[0]?.coding?.[0]?.display,
				codeable_id: appointmentData?.Speciality?.[0]?._id,
				coding_id: appointmentData?.Speciality?.[0]?.coding?.[0]?._id,
			};
			patientData.setVisitDetails({
				...patientData,
			});
			if (patientData.practitioner["speciality"]?.value) {
				if (props.GET_PRACTITIONER) {
					props.GET_PRACTITIONERS({
						OrgID: patientData.practitioner["speciality"]?.codeable_id,
						org_id: appointmentData?.orgid?._id,
					}).then(practitioner_name => {
						const practitioner_name_Data = practitioner_name?.payload?.data?.map(
							(v) => {
								return { value: makeName(v?.name?.[0]) };
							}
						);
						let type = [];
						const practitioner_role_Data = practitioner_name?.payload?.data
							?.map((v) => {
								let typeData = v?.practitioner_type?.[0]?.coding?.[0]?.display ?? "";
								if (type.indexOf(typeData) === -1) {
									type.push(typeData);
									return { value: typeData };
								}
							})
							.filter((r) => !!r);
						let name = [];
						const practitioner_name_Role_data = practitioner_name?.payload?.data
							?.map((v) => {
								let typeData =
									v?.practitioner_role?.[0]?.role?.[0]?.coding?.[0]?.display ?? "";
								if (name.indexOf(typeData) === -1) {
									name.push(typeData);
									return { value: typeData };
								}
							})
							.filter((r) => !!r);
						// const practitioner_name_Role = await props.GET_PRACTITIONER_ROLE({
						//   OrgID: value?.label,
						//   org_id: appointmentData?.healthservice?.[0]?.Organization?.[0]?._id
						// });
						// const practitioner_name_Role_data =
						//   practitioner_name_Role?.payload?.data?.map((v) => {
						//     return { value: v?.code?.[0]?.text };
						//   });
						setpractitioner_name(practitioner_name_Data);
						setpractitioner_role(practitioner_role_Data);
						setpractitioner_name_Role_data(practitioner_name_Role_data);
					});
				}
			}
		}
	}, [props.appointmentData]);

	// const choosemember = () => {
	//     const { choosemember } = props
	//     var arr = []
	//     const data = choosemember?.filter(v => {
	//         v.name.filter(l => {
	//             return arr.push({ value: l?.given })
	//         })
	//     })
	//     console.log(data)
	//     return arr.length > 0 ? arr : []
	// }
	const { parent_id } = props;
	return (
		<Div id={`${parent_id}_practitioner_parent_div`} className="pv-root">
			<Paper id={`${parent_id}_practitioner_parent_paper`} className="pl-root">
				<Div id={`${parent_id}_practitioner_title_div`}>
					<H6
						id={`${parent_id}_practitioner_details_label_h6`}
						className="en-title"
					>
						PRACTITIONER DETAILS
					</H6>
				</Div>
				<Row
					id={`${parent_id}_practitioner_parent_row`}
					className="en-actions-root"
				>
					{/* {walkin &&
                    <Col md={4} lg={4} sm={12} xs={12} className="no-padding-left space-padding-bottom space-padding-top">
                        <TextInput placeholder='Speciality/ Practitioner/ Location' label="Speciality/Practitioner/Location" labelStyle={labelStyle} style={{ ...borderStyle, background: 'transparent' }} hoverColor="#0071F2" />
                    </Col>
                } */}
					<Col
						id={`${parent_id}_practitioner_parent_col`}
						md={12}
						lg={12}
						sm={12}
						xs={12}
						className="no-padding-left"
					>
						<Row
							id={`${parent_id}_practitioner_parent_sub_row`}
							className="en-actions-root"
						>
							<Col
								id={`${parent_id}_practitioner_speciality_col`}
								md={2}
								lg={2}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<Select
									id={`${parent_id}_practitioner_speciality_select`}
									search
									// disabled={!walkin ? false : true}
									disabled={
										props.view
											? true
											: walkin
											? false
											: props.type === "cancel" || props.type === "modify"
											? true
											: false
									}
									options={FlterOptions("SPECIALTY")}
									placeholder="select..."
									value={practitioner?.speciality?.value ?? null}
									onChangeValue={(e) => setDetails("speciality", e)}
									label="Specialty"
									labelStyle={labelStyle}
									inLineStyles={practitioner?.error && practitioner?.error["speciality"] ? errorBorder : borderStyle}
									hoverColor="#0071F2"
									isRequired
									// helperText={
									// 	practitioner?.error && practitioner?.error["speciality"]
									// 		? "Is required"
									// 		: ""
									// }
									error={	practitioner?.error && practitioner?.error["speciality"]
									? true
									: false}
								/>
							</Col>
							<Col
								id={`${parent_id}_practitioner_type_col`}
								md={2}
								lg={2}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<Select
									id={`${parent_id}_practitioner_type_select`}
									search
									// disabled={!walkin ? false : true}
									disabled={
										props.view
											? true
											: walkin
											? false
											: props.type === "cancel" || props.type === "modify"
											? true
											: false
									}
									options={practitioner_role}
									placeholder="select..."
									value={practitioner?.type?.value ?? null}
									onChangeValue={(e) => setDetails("type", e)}
									label="Type"
									labelStyle={labelStyle}
									inLineStyles={borderStyle}
									hoverColor="#0071F2"
								/>
							</Col>
							<Col
								id={`${parent_id}_practitioner_role_col`}
								md={2}
								lg={2}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<Select
									id={`${parent_id}_practitioner_role_select`}
									search
									// disabled={!walkin ? false : true}
									disabled={
										props.view
											? true
											: walkin
											? false
											: props.type === "cancel" || props.type === "modify"
											? true
											: false
									}
									options={getpractitioner_name_Role_data}
									placeholder="select..."
									value={practitioner?.role?.value ?? null}
									onChangeValue={(e) => setDetails("role", e)}
									label="Role"
									labelStyle={labelStyle}
									inLineStyles={borderStyle}
									hoverColor="#0071F2"
								/>
							</Col>
							<Col
								id={`${parent_id}_practitioner_id_name_col`}
								md={4}
								lg={4}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<Select
									id={`${parent_id}_practitioner_id_name_select`}
									placeholder="select..."
									search
									// disabled={!walkin ? false : true}
									disabled={
										props.view
											? true
											: walkin
											? false
											: props.type === "cancel" || props.type === "modify"
											? true
											: false
									}
									options={practitioner_name}
									value={practitioner?.id_name?.value ?? null}
									onChangeValue={(e) => setDetails("id_name", e)}
									label="ID & Name"
									labelStyle={labelStyle}
									inLineStyles={borderStyle}
									hoverColor="#0071F2"
								/>
							</Col>
							<Col
								id={`${parent_id}_practitioner_clinic_col`}
								md={2}
								lg={2}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<Select
									id={`${parent_id}_practitioner_clinic_select`}
									search
									//disabled={!walkin ? false : true}
									disabled={
										props.view
											? true
											: walkin
											? false
											: props.type === "cancel" || props.type === "modify"
											? true
											: false
									}
									options={[{ value: "Apollo Vadapalani" }]}
									placeholder="select..."
									value={practitioner?.clinic?.value ?? null}
									onChangeValue={(e) => setDetails("clinic", e)}
									label="Clinic"
									labelStyle={labelStyle}
									inLineStyles={borderStyle}
									hoverColor="#0071F2"
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Paper>
		</Div>
	);
}

Pactitioner.contextType = AlertContext;
const mapStateToProps = (state) => ({
	oraganizationRegisterPatient:
		state?.OrganizationRegisterPatientApiSlice?.oraganizationRegisterPatient,
});
export default connect(
	mapStateToProps,
	actions
)(withRouter(withQueue(withAllContexts(Pactitioner))));
