import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { Row } from "qdm-component-library";
import "./styles.css";
import VitalSigns from "./vitalSigns";
import { Allergy } from "./allergyFlows";
import { Diagnosis } from "./diagnosisFlow";
import { CheifComplaints } from "./cheifComplaintsFlow";
import { Radiology } from "./radiologyFlow";
import LabOrder from "./labOrderFlow/labOrder";
import { MedicationOrderFrom } from "./medicationFlows";
import TreatmentProcedure from "./treatmentProcedureFlow/treatmentProcedure";
import DoccumentationList from "./documentation/index";
import { OrderSets } from "./orderSetFlow";
const materialStyles = (theme) => ({});

class PatientChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formsList: null,
      patientgetData: null,
    };
  }

  styles = {
    columnStyle: {
      padding: 10,
      backgroundColor: "#fff",
      borderRadius: 5,
    },
    divider: {
      margin: "5px 0px",
      border: "0.5px dashed #EEEEEE",
      borderRadius: 10,
    },
  };

  componentDidMount() {
    this.setState({
      formsList: this.props.formsList,
    });
  }
  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.formsList.length === prevProps.formsList.length &&
        this.props.formsList.every(
          (value, index) => value === prevProps.formsList[index]
        )
      )
    ) {
      this.setState({
        formsList: this.props.formsList,
      });
    }
  }

  getComponets = () => {
    return JSON.stringify(this.state.formsList);
  };

  onEditChart = (id, type) => {
    this.props.onEditChart(id, type);
  };

  render() {
    // const { classes } = this.props;
    const { parent_id } = this.props;
    return (
      <Row
        id={`${parent_id}_patient_chart_parent_row`}
        inLineStyles={{
          height: "calc(100vh - 180px)",
          overflow: "auto",
          padding: "20px",
        }}
      >
        <div
          id={`${parent_id}_patient_chart_parent_div`}
          style={{ width: "100%" }}
        >
          {/* {this.getComponets()} */}

          {this.state?.formsList?.includes("vital_signs") && (
            <div style={{ marginBottom: "12px" }}>
              {" "}
              <VitalSigns />{" "}
            </div>
          )}

          {this.state?.formsList?.includes("allergy") && (
            <div style={{ marginBottom: "12px" }}>
              {" "}
              <Allergy
                parent_id={"patientchart"}
                saveAllergy={this.props.saveAllergy}
                onEditChart={this.onEditChart}
                handleClose={this.props.handleClose}
              />
            </div>
          )}

          {this.state?.formsList?.includes("diagnosis") && (
            <div style={{ marginBottom: "12px" }}>
              {" "}
              <Diagnosis
                parent_id={"patientchart"}
                saveDiagnosis={this.props.saveDiagnosis}
                onEditChart={this.onEditChart}
                handleClose={this.props.handleClose}
              />
            </div>
          )}

          {this.state?.formsList?.includes("cheif_complaints") && (
            <div style={{ marginBottom: "12px" }}>
              {" "}
              <CheifComplaints
                parent_id={"patientchart"}
                saveCheifComp={this.props.saveCheifComp}
                onEditChart={this.onEditChart}
                handleClose={this.props.handleClose}
              />
            </div>
          )}

          {this.state?.formsList?.includes("radiology") && (
            <div style={{ marginBottom: "12px" }}>
              {" "}
              <Radiology
                propsData={{ ...this.props }}
                parent_id={"patientchart"}
                onEditChart={this.onEditChart}
                saveRadiology={this.props.saveRadiology}
                patientgetData={this.props.patientgetData}
                handleClose={this.props.handleClose}
              />
            </div>
          )}

          {this.state?.formsList?.includes("lab_order") && (
            <div style={{ marginBottom: "12px" }}>
              {" "}
              <LabOrder
                propsData={{ ...this.props }}
                parent_id={"patientchart"}
                onEditChart={this.onEditChart}
                saveLabOrders={this.props.saveLabOrders}
                patientgetData={this.props.patientgetData}
                handleClose={this.props.handleClose}
              />
            </div>
          )}

          {this.state?.formsList?.includes("medication") && (
            <div style={{ marginBottom: "12px" }}>
              {" "}
              <MedicationOrderFrom
                propsData={{ ...this.props }}
                parent_id={"patientchart"}
                onEditChart={this.onEditChart}
                handleClose={this.props.handleClose}
              />
            </div>
          )}

          {this.state?.formsList?.includes("orders_sets") && (
            <div style={{ marginBottom: "12px" }}>
              {" "}
              <OrderSets
                setOpen={true}
                handlingClose={this.props.handleOrderSetClose}
              />
            </div>
          )}

          {this.state?.formsList?.includes("treatment_procedure") && (
            <div style={{ marginBottom: "12px" }}>
              {" "}
              <TreatmentProcedure
                propsData={{ ...this.props }}
                parent_id={"patientchart"}
                onEditChart={this.onEditChart}
                saveTreatment={this.props.saveTreatment}
                patientgetData={this.props.patientgetData}
                handleClose={this.props.handleClose}
              />
            </div>
          )}

          {this.state?.formsList?.includes("docc_ui") && (
            <div style={{ marginBottom: "12px" }}>
              {" "}
              <DoccumentationList
                parent_id={"patientchart"}
                onEditChart={this.onEditChart}
                saveDoccumentation={this.props.saveDoccumentation}
                patientgetData={this.props.patientgetData}
                handleClose={this.props.handleClose}
              />
            </div>
          )}
        </div>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({});

export default withStyles(materialStyles)(
  connect(mapStateToProps, actions)(withRouter(PatientChart))
);
