import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Div,
  Paper,
  H6,
  Divider,
  Row,
  Col,
  Button,
  Image,
} from "qdm-component-library";
import "./navBar.css";
import { withRouter } from "react-router-dom";
import { Popover, makeStyles, Typography, Modal } from "@material-ui/core";
import { UploadFiles } from "./";
import { Routes } from "../../../router/routes";
import { DrawerMeetingContext } from "../../../contexts";
import LoadingComp from "../../LoadingComp";

const useStyles = makeStyles((theme) => ({
  muiPopovers: {
    boxShadow: "0px 25px 50px #00000029",
    borderRadius: 8,
    marginTop: 10,
    padding: 10,
  },
  mykadbtn: {
    fontFamily: "pc_medium",
    fontSize: "14px !important",
    color: "#6F6F6F !important",
    backgroundColor: "#f2f2f2 !important",
  },
}));

export const Component = (props) => {
  const {
    sucessBtnName,
    navBarArr = [],
    title,
    btn = "Register & continue",
    noUpload,
    successfun,
    activeId = 1,
    navClick = () => null,
    putOnHold,
    parent_id,
    isRegisterFromMyKad,
    fetchMyKad = () => {},
  } = props;

  const [isUpload, setIsUpload] = React.useState(null);
  const [files, setFiles] = React.useState([]);
  const AVCMeeting = React.useContext(DrawerMeetingContext);

  useEffect(() => {
    setFiles(props?.initialFiles);
  }, [props?.initialFiles]);

  const redirectLink = (data) => {
    navClick(data.id);
  };

  const uploadFun = (e) => {
    setIsUpload(e?.currentTarget);
  };
  const goback = () => {
    if (props.isAVC && !AVCMeeting.open) {
      window.location.href = "/dashboard";
    } else {
      if (props.goBack) {
        props.goBack();
      } else {
        props.history.goBack();
      }
    }
  };
  const uploadDocument = (files) => {
    setFiles(files);
  };
  const classes = useStyles();
  const handleKadOpen = () => {
    props.handleKad()
    fetchMyKad()
  }

  return (
    <div id={`${parent_id}_topnavbar_parent_div`} style={{ height: "56px" }}>
      <Paper
        id={`${parent_id}_topnavbar_parent_paper`}
        style={{
          margin: "0px",
          position: "fixed",
          zIndex: "999",
          background: "white",
          boxShadow: "0px 3px 6px #0000000A",
        }}
      >
        <Row
          id={`${parent_id}_topnavbar_parent_row`}
          className={
            navBarArr.length > 0 ? "topNav-top-Parent" : "topNav-top-Parent1"
          }
        >
          <Col id={`${parent_id}_topnavbar_parent_log_col`}>
            <Div
              id={`${parent_id}_topnavbar_parent_log_div`}
              className={"topNav-parent"}
            >
              {/* <i onClick={() => goback()} className="fa fa-angle-left topNav-backIcon" aria-hidden="true"></i> */}
              <Div
                id={`${parent_id}_topnavbar_log_sub_div`}
                className="uploadIcon"
                onClick={() => goback()}
              >
                <Image
                  id={`${parent_id}_topnavbar_log_image`}
                  src="images/icons8-left.svg"
                  alt="left"
                />
              </Div>
              <H6
                id={`${parent_id}_topnavbar_title_h6`}
                className={"topNav-title"}
              >
                {props.parent_edit_id ? "Modify Patient Details" : title}
              </H6>
              {navBarArr.length > 0 ? (
                <Divider
                  id={`${parent_id}_topnavbar_title_rightside_divider`}
                  className={"topNav-divider"}
                  orientation="vertical"
                />
              ) : (
                <></>
              )}
            </Div>
          </Col>
          <Col id={`${parent_id}_topnavbar_title_col`}>
            {navBarArr.map((val, i) => {
              return (
                <p
                  id={`${parent_id}_${i}_topnavbar_title_label_p`}
                  onClick={() => redirectLink(val)}
                  className={`${
                    activeId === val.id ? "topNav-active" : ""
                  } topNav-tabs`}
                >
                  {val.label}
                  {activeId === val.id && (
                    <Div
                      id={`${parent_id}_${i}_topnavbar_title_bottomdivider_div`}
                      className="bottomDivider"
                    ></Div>
                  )}
                </p>
              );
            })}
          </Col>
          {props.parent_edit_id ? (
            <Col
              id={`${parent_id}_topnavbar_buttons_div`}
              className={`topNav-actions-parent`}
            >
              <Button
                id={`${parent_id}_topnavbar_Register_continue_button`}
                className={"topNav-actions successBtn"}
                // disabled={props.view}
                // onClick = {() => {props.handleEditPatient()}}
                onClick={
                  successfun
                    ? () => {
                        successfun(files);
                      }
                    : () => null
                }
              >
                {btn === "Registering" ? "Saving" : "Save Changes"}
              </Button>
              <Button
                id={`${parent_id}_topnavbar_cancel_button`}
                variant="text"
                className={"topNav-actions cancelBtn"}
                onClick={async () => {
                  await goback();
                }}
                disabled={props.view}
              >
                Cancel
              </Button>

              {!noUpload && (
                <Div
                  id={`${parent_id}_topnavbar_upload_div`}
                  className="uploadIcon"
                  style={{ margin: "0px" }}
                >
                  <Image
                    id={`${parent_id}_topnavbar_upload_image`}
                    src="images/icons8-upload.svg"
                    alt="upload"
                    onClick={(e) => uploadFun(e)}
                    className=""
                  />
                </Div>
              )}
            </Col>
          ) : (
            !props.view && (
              <Col
                id={`${parent_id}_topnavbar_buttons_div`}
                className={`topNav-actions-parent`}
              >
                <Button
                  id={`${parent_id}_topnavbar_Register_continue_button`}
                  className={"topNav-actions successBtn_"}
                  disabled={props.view || btn === "Cancelling" ? true : false}
                  onClick={
                    successfun
                      ? () => {
                          successfun(files);
                        }
                      : () => null
                  }
                >
                  {sucessBtnName ? sucessBtnName : btn}
                </Button>
                {!props.newUser && (
                  <Button
                    id={`${parent_id}_topnavbar_put_on_hold_button`}
                    variant="outlined"
                    disabled={props.view}
                    className={"topNav-actions topNav-actions-empty"}
                    onClick={
                      putOnHold
                        ? async () => {
                            await putOnHold();
                            props.alertHold();
                            window.location.href = Routes.dashboard;
                          }
                        : () => null
                    }
                  >
                    Put on Hold
                  </Button>
                )}
                <Button
                  id={`${parent_id}_topnavbar_cancel_button`}
                  variant="text"
                  className={"topNav-actions cancelBtn"}
                  onClick={async () => {
                    await goback();
                  }}
                  disabled={props.view}
                >
                  Cancel
                </Button>
                {!noUpload && (
                  <Divider
                    id={`${parent_id}_topnavbar_leftside_divider`}
                    className={"topNav-divider topNav-divider-actions"}
                    orientation="vertical"
                  />
                )}
                {!noUpload && (
                  <Div
                    id={`${parent_id}_topnavbar_upload_div`}
                    className="uploadIcon"
                    inLineStyles={
                      !props?.newUser
                        ? {
                            minWidth: "7%",
                            marginLeft: "18px",
                            height: "32px",
                            marginTop: "1%",
                          }
                        : {
                            marginLeft: 18,
                          }
                    }
                  >
                    <Image
                      id={`${parent_id}_topnavbar_upload_image`}
                      src="images/icons8-upload.svg"
                      alt="upload"
                      onClick={(e) => uploadFun(e)}
                      className=""
                    />
                  </Div>
                )}
                {isRegisterFromMyKad ? (
                  <>
                    <Divider
                      id={`${parent_id}_topnavbar_leftside_divider`}
                      className={"topNav-divider topNav-divider-actions"}
                      orientation="vertical"
                    />
                    <Div>
                      <Button
                        id={`${parent_id}_topnavbar_mykad_button`}
                        variant="text"
                        className={`${classes.mykadbtn} topNav-actions`}
                        onClick={handleKadOpen}
                        disabled={props.view}
                      >
                        MyKad
                      </Button>
                    </Div>
                  </>
                ) : null}
              </Col>
            )
          )}
        </Row>
        <Popover
          anchorEl={isUpload}
          open={Boolean(isUpload)}
          onClose={() => uploadFun(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          style={{ zIndex: 99999 }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ paper: classes.muiPopovers }}
        >
          <UploadFiles
            id={`${parent_id}_topnavbar_upload_uploadfiles`}
            uploadDocument={uploadDocument}
            files={files}
          />
        </Popover>
        <Modal
          id={`${parent_id}_topnavbar_mykad_modal`}
          width={1000}
          open={props.kadOpen}
          className="docc-modal"
          onOpen
          // onClose={() => setMyKadModalOpen(false)}
        >
          <Div
            style={{
              borderRadius: 16,
              background: "#fff",
              marginTop: 30,
              paddingTop: 75,
              paddingBottom: 75,
              paddingLeft: 25,
              paddingRight: 25,
              width: 1000,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <LoadingComp
              loaderStyle={{ width: 60, height: 60 }}
              style={{ marginBottom: 40 }}
              loading={true}
            />
            <Div>
              <Typography style={{ fontSize: 20, color: "#001C3C" }}>
                Fetching data from MyKad
              </Typography>
            </Div>
          </Div>
        </Modal>
      </Paper>
    </div>
  );
};

Component.propTypes = {
  btn: PropTypes.string,
  title: PropTypes.string,
  withBackButton: PropTypes.bool,
  navBarArr: PropTypes.array,
  titleIcon: PropTypes.element,
  onTitleIconClicked: PropTypes.func,
};

Component.defaultProps = {
  withBackButton: false,
};

const TopNavbar = (props) => <Component {...props} />;

export default withRouter(TopNavbar);
