import React from 'react';
import { Div, H6, Icons } from 'qdm-component-library';
import { Popover } from '@material-ui/core';
import { UIColor } from '../../../utils';

export const Header = (props) => {

    const {
        icon = "",
        title = "",
        iconBackground = "",
        toggle = null,
        open = false
    } = props;

    const styles = {
        rowFlex: {
            display: 'flex',
            alignItems: 'center'
        },
        iconWrapper: {
            width: 30,
            height: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
        },
        title: {
            margin: 0,
            fontSize: 14,
            marginLeft: 10,
            fontWeight: 600,
            color: UIColor.primaryText.color
        },
        iconSize: {
            width: 15,
            height: 15,
            color: UIColor.secondaryText.color
        },
        filter: {
            backgroundColor: UIColor.differentiationBackground.color,
            borderRadius: 100,
            display: 'flex',
            alignItems: 'center',
            padding: '3px 10px',
            marginRight: 10
        },
        filterText: {
            fontSize: 12,
            color: '#919191'
        },
        bullet: {
            width: 6.5,
            height: 6.5,
            borderRadius: '50%',
        },
        popoverStyle: {
            padding: 10
        },
        span: {
            marginLeft: 5,
            fontSize: 12
        }
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentStatus, setcurrentStatus] = React.useState("All")
    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'simple-popover' : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (l) => {
        setAnchorEl(null);
        if (l?.value?.length > 2) {
            setcurrentStatus(l.value)
        } else {
            setcurrentStatus(currentStatus)
        }

    };

    React.useEffect(() => {
        if (currentStatus === "Active") {
            props.handleStatusActive();
        } else if (currentStatus === "InActive") {
            props.handleStatusInActive();
        } else if (currentStatus === "Resolved") {
            props.handleStatusResolved();
        } else if (currentStatus === "All") {
            props.handleStatusAll();
        }
    }
        , [currentStatus])

    const statusCode = [
        {
            color: '#000',
            value: 'All'
        },
        {
            color: '#38c20a',
            value: 'Active'
        },
        {
            color: '#F58B00',
            value: 'InActive'
        },
        {
            color: '#6F6F6F',
            value: 'Resolved'
        },
    ]
    const { parent_id } = props
    return (
        <Div
            id={`${parent_id}_header_parent_div`}
            style={{ ...styles.rowFlex, cursor: 'pointer', padding: 10 }}>
            <Div
                id={`${parent_id}_header_title_div`}
                style={{ ...styles.rowFlex, flex: 1 }} onClick={toggle}>
                {icon &&
                    <Div
                        id={`${parent_id}_header_icon_div`}
                        style={{
                            ...styles.iconWrapper,
                            backgroundColor: iconBackground ?? '#E5F1FE'
                        }}>
                        {icon}
                    </Div>
                }
                <H6
                    id={`${parent_id}_header_${title.replaceAll(" ","_")}_h6`}
                    style={styles.title}>{title}</H6>
            </Div>
            <Div
                id={`${parent_id}_header_status_div`}
                style={styles.rowFlex} >
                <Div
                    id={`${parent_id}_header_${currentStatus.replaceAll(" ","_")}_div`}
                    onClick={e => handleClick(e)} aria-describedby={id} style={styles.filter} >
                    <H6
                        id={`${parent_id}_header_${currentStatus.replaceAll(" ","_")}_h6`}
                        style={styles.filterText}>{currentStatus}</H6>
                    <Icons
                        id={`${parent_id}_header_filter_icons`}
                        fontIcon="filter" style={{ marginLeft: 5, color: UIColor.tertiaryText.color }} />
                </Div>
                {
                    open ? <Icons
                        id={`${parent_id}_header_chevron-up_icons`}
                        onClick={toggle} fontIcon="chevron-up" style={styles.iconSize} /> : <Icons
                        id={`${parent_id}_header_chevron-down_icons`}
                        onClick={toggle} fontIcon="chevron-down" style={styles.iconSize} />
                }
            </Div>
            <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Div
                    id={`${parent_id}_header_statusCode_div`}
                    style={styles.popoverStyle}>
                    {
                        statusCode && statusCode.map((l, i) => {
                            return (
                                <Div
                                    id={`${parent_id}_${i}_header_statusCode_div`}
                                    style={{
                                        ...styles.rowFlex,
                                        paddingBottom: i < statusCode?.length - 1 ? 5 : 0,
                                        cursor: 'pointer'
                                    }}
                                    onClick={(e) => handleClose(l)}>
                                    <Div
                                        id={`${parent_id}_${i}_header_${l?.value}_div`}
                                        style={{ ...styles.bullet, backgroundColor: l?.color }} />
                                    <H6
                                        id={`${parent_id}_${i}_header_${l?.value}_h6`}
                                        style={styles.span}>{l?.value}</H6>
                                </Div>
                            )
                        })
                    }
                </Div>
            </Popover>
        </Div>
    )
}